import React from 'react'
import styled, { keyframes, css } from 'styled-components'
import SEO from 'structure/seo'
import Layout from 'structure/layout'
import { useInView } from 'react-intersection-observer'

import { 
  Header, 
  SayHello,
  HeaderInternal,
} from 'partials'

import { 
  ChaosLineFull, 
  media,
  GreenFieldIcon,
  BrownFieldIcon,
  Text,
  SectionHeader,
} from 'ui'

const Services = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
  })

  const { ref: ref2, inView: inView2 } = useInView({
    triggerOnce: true,
  })

  return (
    <Layout>
      <SEO
        url='/services'
      />

      <Header onlyHead />

      <HeaderInternal
        title='A Full Solution Co.'
        description='We want to break the "let it brake" software culture. We see no more business or technical reasons for skipping the good practices of the clean and maintainable code base, scalable architecture, decoupled teams strategies, and authentic agile practices.'
      />
      
      <Chaos>
        <ChaosLineFull color='#777' />
      </Chaos>

      <ServicesBox>

        <Item ref={ref}>
          <ServiceIcon animation={inView}>
            <GreenFieldIcon />
          </ServiceIcon>
          <SectionHeader
            title='Green field'
            description='We help you to build a completely new and taylor made digital product fromscratch, web or mobile, from design to execution and delivery.'
          />

          <Target>
            <TargetItem>
              <TargetTitle>For your company</TargetTitle>
              <TargetDescription>
                We combine deep business knowledge with the development of innovative and user friendly digital products so that you can solve your business problems in an agile and efficient way, guaranteeing you a competitive advantage.
              </TargetDescription>
            </TargetItem>
            <TargetItem>
              <TargetTitle>For your customers</TargetTitle>
              <TargetDescription>
                We help you to quickly add value to your customers. Combining business acumen, design based on customer experience and excellence in software engineering, we help you take your ideas for innovative digital products off the ground, offering the best experience to your customers.
              </TargetDescription>
            </TargetItem>
          </Target>
        </Item>

        <Item ref={ref2}>
          <ServiceIcon animation={inView2}>
            <BrownFieldIcon />
          </ServiceIcon>
          <SectionHeader
            title='Brown field'
            description='We help you to improve your digital product from where it is, web or mobile, from understanding the problems to execution and delivery.'
          />

          <Target>
            <TargetItem>
              <TargetTitle>For your company and customers</TargetTitle>
              <TargetDescription>
                We help you to improve and scale up your existing digital products, leading your company to face inernal operational inneficiencies and customer-centric business challenges with cutting edge technology.
              </TargetDescription>
            </TargetItem>
          </Target>
        </Item>

      </ServicesBox>

      <SayHello />
    </Layout>
  )
}

const line = keyframes`
  to {
    stroke-dashoffset: 0;
  }
`

const Chaos = styled.section`
  position: relative;
  height: 30rem;

  svg {
    position: absolute;
    width: 2604px;
    height: 100%;
    left: 50%;
    margin-left: -1302px;
  }

  ${media.lessThan('phone')`
    height: 12rem;
  `}
`

const Item = styled.div`
  padding: 8rem;

  &:first-child {
    padding-top: 0;
  }

  &:not(:first-child) {
    border-top: 1px solid #eee;
  }
`

const ServicesBox = styled.section`
  padding: 8rem 0;
  max-width: var(--size-main);
  margin: 0 auto;
  width: 100%;
`

const ServiceIcon = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12rem;
  height: 12rem;
  margin: 0 auto;

  svg {
    width: 100%;
    height: auto;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60%;
    height: 60%;
    margin-left: -30%;
    margin-top: -30%;
    background: var(--color-border);
    z-index: -1;
  }

  ${({ animation }) => animation && css`
    #green-field-icon,
    #brown-field-icon {
      --line-size: 200;
      stroke-dasharray: var(--line-size);
      stroke-dashoffset: var(--line-size);
      animation: ${line} 1.3s linear forwards .5s;
    }
  `}
`

const Target = styled.div`
  display: flex;
  max-width: var(--size-main);
  margin: 8rem auto 0;
  text-align: center;
  justify-content: center;
`

const TargetItem = styled.div`
  padding: 0 4rem;
  flex-basis: 50%;
`

const TargetTitle = styled.h3`
  font-size: 2.6rem;
  color: var(--color-primary);
  font-family: var(--font-title);
  padding: .5rem 0;
  margin: 0;

  &:after {
    content: '';
    display: block;
    margin: 1rem auto .5rem;
    width: 2rem;
    height: 2px;
    background: var(--color-accent);
  }
`

const TargetDescription = styled(Text)`
`

export default Services
